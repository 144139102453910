export default [
  {
    key: 'id',
    type: 'human-id',
    label: 'ID',
  },
  {
    key: 'created_at',
    label: 'Created at',
    type: 'datetime',
    filter: {
      startKey: 'start_date',
      endKey: 'end_date',
      type: 'datetime',
    },
  },
  {
    key: 'v1_order_number',
    label: 'V1 Order Number',
    visibility: ['view'],
  },
  {
    key: 'supporter.nickname',
    label: 'Buyer',
    type: 'profile',
    image: 'supporter.avatar',
    sortable: false,
    url: {
      callback(data) {
        return `/user/${data.userID}`
      },
    },
  },
  {
    key: 'supporter.email',
    type: 'email',
    label: 'Supporter email',
    visibility: ['view'],
  },
  {
    key: 'price_as_charged',
    label: 'Price',
    type: {
      callback(data) {
        return data.currency_as_charged === 'GANK_GOLD' ? 'gold' : undefined
      },
    },
    prefix: {
      callback(data) {
        return data.currency_as_charged && data.currency_as_charged !== 'GANK_GOLD' ? `${data.currency_as_charged.toUpperCase()} ` : undefined
      },
    },
  },
  {
    key: 'creator.nickname',
    label: 'Creator',
    type: 'profile',
    sortable: false,
    image: 'creator.avatar',
    url: {
      callback(data) {
        return data.creator && data.creator.id ? `/user/${data.creator.id}` : null
      },
    },
  },
  {
    key: 'creator.email',
    type: 'email',
    label: 'Creator email',
    visibility: ['view'],
  },
  {
    key: 'ui_title',
    label: 'Title',
    sortable: false,
    visibility: ['view'],
  },
  {
    key: 'ui_description',
    label: 'Description',
    visibility: ['view'],
  },
  {
    key: 'v1_catalog_shipping_instruction',
    label: 'Note from buyer',
    visibility: ['view'],
  },
  {
    key: 'currency_as_charged',
    label: 'Currency',
    type: 'dot',
    map: {},
    sortable: false,
    filter: {
      key: 'currency_as_charged',
      type: 'select',
      options: [
        { value: 'USD', text: 'USD' },
        { value: 'IDR', text: 'IDR' },
        { value: 'MYR', text: 'MYR' },
        { value: 'PHP', text: 'PHP' },
        { value: 'SGD', text: 'SGD' },
        { value: 'THB', text: 'THB' },
        { value: 'TWD', text: 'TWD' },
        { value: 'VND', text: 'VND' },
        { value: 'GANK_GOLD', text: 'GOLD' },
      ],
    },
  },
]
