import { transactionFilterPaymentV2 } from '@/list/payment-method'

export default [
  {
    key: 'id',
    type: 'human-id',
    label: 'Order Number',
  },
  {
    key: 'created_at',
    label: 'Created at',
    type: 'datetime',
    filter: {
      startKey: 'start_date',
      endKey: 'end_date',
      type: 'datetime',
    },
  },
  {
    key: 'payment_status',
    label: 'Status',
    type: 'dot',
    sortable: false,
    map: {
      CREATED: 'warning',
      FAILED: 'danger',
      CREATED_CANCELLED: 'danger',
      SUCCEED: 'success',
    },
  },
  {
    key: 'supporter.nickname',
    label: 'Supporter',
    type: 'profile',
    sortable: false,
    image: 'supporter.avatar',
    url: {
      callback(data) {
        return data.supporter && data.supporter.id ? `/user/${data.supporter.id}` : null
      },
    },
  },
  {
    key: 'supporter.email',
    type: 'email',
    label: 'Supporter email',
    visibility: ['view'],
  },
  {
    key: 'creator.nickname',
    label: 'Creator',
    type: 'profile',
    sortable: false,
    image: 'creator.avatar',
    url: {
      callback(data) {
        return data.creator && data.creator.id ? `/user/${data.creator.id}` : null
      },
    },
  },
  {
    key: 'seller_user_email',
    type: 'email',
    label: 'Creator Email',
    visibility: ['view'],
  },
  {
    key: 'seller_user_profile.countryCode',
    type: 'country',
    label: 'Creator country',
    visibility: ['view'],
  },
  {
    key: 'donationData.donator_message',
    label: 'Message',
    visibility: ['view'],
  },
  {
    key: 'ui_title',
    label: 'Title',
    sortable: false,
    visibility: ['view'],
  },
  {
    key: 'ui_description',
    label: 'Description',
    visibility: ['view'],
  },
  // all value is in usd or gold
  {
    key: 'price',
    label: 'Amount',
    type: {
      callback(data) {
        return data.currency === 'GANK_GOLD' ? 'gold' : undefined
      },
    },
    prefix: {
      callback(data) {
        return data.currency !== 'GANK_GOLD' ? '$' : undefined
      },
    },
  },
  {
    key: 'price_as_charged',
    label: 'Currency',
    type: {
      callback(data) {
        return data.currency_as_charged === 'GANK_GOLD' ? 'gold' : undefined
      },
    },
    prefix: {
      callback(data) {
        return data.currency_as_charged && data.currency_as_charged !== 'GANK_GOLD' ? `${data.currency_as_charged.toUpperCase()} ` : undefined
      },
    },
  },
  {
    key: 'fee.price_as_charged_applied_fee',
    label: 'Applied Fee',
    type: {
      callback(data) {
        return data.fee && data.fee.currency_as_charged === 'GANK_GOLD' ? 'gold' : undefined
      },
    },
    prefix: {
      callback(data) {
        return data.fee && data.fee.currency_as_charged && data.fee.currency_as_charged !== 'GANK_GOLD' ? `${data.fee.currency_as_charged.toUpperCase()} ` : undefined
      },
    },
  },
  {
    key: 'fee.price_as_charged_conversion_fee',
    label: 'Conversion Fee',
    type: {
      callback(data) {
        return data.fee && data.fee.currency_as_charged === 'GANK_GOLD' ? 'gold' : undefined
      },
    },
    prefix: {
      callback(data) {
        return data.fee && data.fee.currency_as_charged && data.fee.currency_as_charged !== 'GANK_GOLD' ? `${data.fee.currency_as_charged.toUpperCase()} ` : undefined
      },
    },
  },
  transactionFilterPaymentV2,
  {
    key: 'currency_as_charged',
    label: 'Currency',
    type: 'dot',
    map: {},
    sortable: false,
    filter: {
      key: 'currency_as_charged',
      type: 'select',
      options: [
        { value: 'USD', text: 'USD' },
        { value: 'IDR', text: 'IDR' },
        { value: 'MYR', text: 'MYR' },
        { value: 'PHP', text: 'PHP' },
        { value: 'SGD', text: 'SGD' },
        { value: 'THB', text: 'THB' },
        { value: 'TWD', text: 'TWD' },
        { value: 'VND', text: 'VND' },
        { value: 'GANK_GOLD', text: 'GOLD' },
      ],
    },
  },
]
