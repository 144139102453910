import { get, set } from '@/helper/localStorage'
import _get from 'lodash/get'
import fields from '@/field/orderV2'

export default {
  namespaced: true,
  state: {
    tableFields: get('orderV2TableFields', fields),
    tableData: [],
    tableSort: get('orderV2TableSort'),
    tableFilter: get('orderV2TableFilter', []),
    detailData: get('orderV2DetailData'),
    tablePagination: get('orderV2TablePagination'),
    tableKeyword: get('orderV2TableKeyword'),
    loadingTable: false,
    loadingDetail: false,
    loadingSubmit: false,
  },
  getters: {
    tableFields: state => {
      if (!state.tableFields) {
        return undefined
      }

      // only the visibility is customizable by the user
      const visibilities = state.tableFields.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.visibility }), {},
      )

      // the other props uses the default fields configuration
      return fields.map(item => ({
        ...item,
        // only replace the visibility value if user override it
        visibility: item.key in visibilities ? visibilities[item.key] : item.visibility,
      }))
    },
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      return `${state.tableSort.key} ${state.tableSort.direction}`
    },
    tableData: state => state.tableData.map(item => {
      let supporter = null
      let creator = null

      supporter = {
        id: item.buyer_user_id || item.usecase_data?.data?.buyer_user_id,
        avatar: item.buyer_user_avatar_url,
        nickname: item.buyer_user_nickname || item.usecase_data?.data?.buyer_user_nickname,
        email: item.buyer_user_email || item.usecase_data?.data?.buyer_user_email,
      }

      creator = {
        id: item.seller_user_id || item.usecase_data?.data?.creator_user_id,
        avatar: item.seller_user_avatar_url,
        nickname: item.seller_user_nickname || item.usecase_data?.data?.creator_user_nickname,
        email: item.seller_user_email || item.usecase_data?.data?.creator_user_email,
      }

      return ({
        ...item,
        id: item.human_id,
        supporter,
        creator,
        v1_order_number: item.v1_order_number || '-',
      })
    }),
    tableFilter: state => {
      if (!state.tableFilter) {
        return []
      }

      return state.tableFilter
        .reduce((acc, elem) => Object.assign(acc, {
          [elem.key]: Array.isArray(elem.value)
            ? elem.value.join(',')
            : elem.value,
        }), {})
    },
    tableParams: (state, getters, rootState) => ({
      search_keywords: state.tableKeyword,
      ...getters.tableFilter,
      orderBy: getters.tableSort,
      page: _get(state, 'tablePagination.currentPage', 1),
      perPage: rootState.appConfig.settings.tablePerPage,
    }),
    detailData: state => {
      if (!state.detailData) {
        return undefined
      }

      return {
        ...state.detailData,
        type: state.detailData.isRenewal ? 'auto renewal' : 'one-time',
        payment_status: state.detailData.status,
        active_status: state.detailData.isActive ? 'active' : 'inactive',
        cancelled_status: state.detailData.isCancelled ? 'cancelled' : undefined,
        creator: {
          ...state.detailData.creator,
          fullName: [_get(state.detailData, 'creator.firstName'), _get(state.detailData, 'creator.lastName')]
            .filter(name => name)
            .join(' ')
            .replace('  ', ' '), // remove double space
        },
        subscriber: {
          ...state.detailData.subscriber,
          fullName: [_get(state.detailData, 'subscriber.firstName'), _get(state.detailData, 'subscriber.lastName')]
            .filter(name => name)
            .join(' ')
            .replace('  ', ' '), // remove double space
        },
      }
    },
  },
  mutations: {
    SET_TABLE_FIELDS(state, val) {
      set('orderV2TableFields', val)
      state.tableFields = val
    },
    RESET_TABLE_FIELDS(state) {
      set('orderV2TableFields', fields)
      state.tableFields = fields
    },
    SET_TABLE_DATA(state, val) {
      set('orderV2Table', val)
      state.tableData = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('orderV2TablePagination', val)
      state.tablePagination = val
    },
    SET_TABLE_KEYWORD(state, val) {
      set('orderV2TableKeyword', val)
      state.tableKeyword = val
    },
    SET_DETAIL_DATA(state, val) {
      set('orderV2DetailData', val)
      state.detailData = val
    },
    SET_TABLE_SORT(state, val) {
      set('orderV2TableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('orderV2TableFilter', val)
      state.tableFilter = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
  },
  actions: {
    async getTableData({
      state, commit, getters, rootState,
    }) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      await this._vm.$http.get('/v2/payment/admin/transactions?usecases_client=ADMIN_LIST_LISTING_TX', { params })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data || [])
          commit('SET_TABLE_PAGINATION', {
            currentPage: state.tablePagination?.currentPage ?? 1,
            totalPage: data.total / (state.tablePagination?.currentPage ?? rootState.appConfig.settings.tablePerPage),
            perPage: state.tablePagination?.perPage ?? rootState.appConfig.settings.tablePerPage,
            totalData: data.total,
          })
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    getDetail({ commit }, id) {
      commit('SET_LOADING_DETAIL', true)

      this._vm.$http.get(`/v1/orders/${id}`)
        .then(({ data }) => {
          commit('SET_DETAIL_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_DETAIL', false)
        })
    },
    changeStatus({ commit }, { id, data }) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.post(`/v1/orders-v2/${id}/status`, data)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    downloadTableData({ commit, getters }) {
      commit('SET_LOADING_SUBMIT', true)
      const { tableParams: params } = getters
      const payload = {
        ...params,
        search_keywords: undefined,
        searchKeywords: params.search_keywords,
        statuses: params.statuses ? [params.statuses] : undefined,
        perPage: Number(params.perPage),
        usecasesClient: 'ADMIN_LIST_LISTING_TX',
        start_date: undefined,
        startDate: params.start_date,
        end_date: undefined,
        endDate: params.end_date,
      }
      return this._vm.$http.post('/v1/bo/users/export/orders', payload)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
  },
}
