import countries from '@/list/countries'

export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'nickname',
    label: 'Username',
    type: 'profile',
    image: 'avatar',
  },
  {
    key: 'fullName',
    label: 'Name',
    visibility: ['view'],
  },
  {
    key: 'email',
    type: 'email',
    label: 'Email',
  },
  {
    key: 'profile.countryCode',
    label: 'Country',
    type: 'country',
    filter: {
      key: 'countryCode',
      type: 'select',
      options: countries,
    },
  },
  {
    key: 'roles',
    label: 'Role',
    type: 'badges',
    map: {
      administrator: 'primary',
      customer: 'secondary',
    },
    filter: {
      key: 'userRoles',
      type: 'multiple',
      options: [
        { value: 'customer', text: 'Customer' },
        { value: 'super-admin', text: 'Super admin' },
        { value: 'administrator', text: 'Admin' },
        { value: 'finance', text: 'Finance' },
        { value: 'marketer', text: 'Marketer' },
        { value: 'moderator', text: 'Moderator' },
        { value: 'customer-support', text: 'Customer support' },
        { value: 'beta-tester', text: 'Beta tester' },
      ],
    },
    visibility: ['view'],
  },
  {
    key: 'userType',
    label: 'Type',
    type: 'dot',
    map: {
      creator: 'success',
      supporter: 'danger',
    },
    filter: {
      key: 'userTypes',
      type: 'select',
      options: [
        { value: 'creator', text: 'Creator' },
        { value: 'supporter', text: 'Supporter' },
      ],
    },
  },
  {
    key: 'creatorType',
    label: 'Creator type',
    type: 'dot',
    map: {
      ORGANIC: 'warning',
      CONTRACTED: 'success',
      'EX-CONTRACTED': 'danger',
    },
    visibility: ['view'],
    filter: {
      key: 'creatorType',
      type: 'select',
      options: [
        { value: 'ORGANIC', text: 'Organic' },
        { value: 'CONTRACTED', text: 'Contracted' },
        { value: 'EX-CONTRACTED', text: 'Ex-Contracted' },
      ],
    },
  },
  {
    key: 'creatorContract.contractStart',
    label: 'Contract start',
    type: 'datetime',
    visibility: ['view'],
  },
  {
    key: 'creatorContract.contractEnd',
    label: 'Contract end',
    type: 'datetime',
    visibility: ['view'],
  },
  {
    key: 'tier',
    label: 'Tier',
    type: 'badge',
    map: {
      BOOST_PLUS: 'primary',
      BOOST: 'warning',
      FREE: 'success',
    },
    filter: {
      key: 'tiers',
      type: 'multiple',
      options: [
        { value: 'FREE', text: 'Free' },
        { value: 'BOOST', text: 'Boost' },
        { value: 'BOOST_PLUS', text: 'Boost Plus' },
      ],
    },
  },
  {
    key: 'active_status',
    label: 'Active',
    type: 'dot',
    map: {
      active: 'success',
      inactive: 'danger',
    },
    visibility: ['view'],
    filter: {
      key: 'active',
      type: 'select',
      options: [
        { value: true, text: 'Active' },
        { value: false, text: 'Inactive' },
      ],
    },
  },
  {
    key: 'verified_status',
    label: 'Email verified',
    type: 'dot',
    map: {
      verified: 'success',
      unverified: 'danger',
    },
    visibility: ['view'],
    filter: {
      key: 'isVerified',
      type: 'select',
      options: [
        { value: true, text: 'Verified' },
        { value: false, text: 'Unverified' },
      ],
    },
  },
  {
    key: 'userInterestsName',
    label: 'Interest',
    type: 'badges',
    map: {},
    visibility: ['view'],
  },
  {
    key: 'tags',
    label: 'Flags',
    type: 'badges',
    map: {
      SFW: 'success',
      NSFW: 'primary',
      PORN: 'primary',
    },
    filter: {
      key: 'tags',
      type: 'select',
      options: [
        { value: 'SFW', text: 'SFW' },
        { value: 'NSFW', text: 'NSFW' },
        { value: 'PORN', text: 'PORN' },
      ],
    },
    visibility: ['view'],
  },
  {
    key: 'adminComment',
    label: 'Comments',
    visibility: ['view'],
  },
  {
    key: 'createdAt',
    label: 'Registered at',
    type: 'datetime',
    filter: {
      startKey: 'startedAt',
      endKey: 'endedAt',
      type: 'datetime',
    },
  },
  {
    key: 'updatedAt',
    label: 'Updated at',
    type: 'datetime',
    visibility: ['view'],
  },
  {
    key: 'deletedAt',
    label: 'Deleted at',
    type: 'datetime',
    visibility: ['view'],
  },
  // TODO: the 'All' value doesn't really show all data. only the not-deleted one.
  {
    label: 'Deleted',
    visibility: false,
    filter: {
      key: 'onlyDeletedUser',
      type: 'select',
      options: [
        { value: true, text: 'Deleted' },
      ],
    },
  },
  {
    key: 'isSuspended',
    label: 'Suspend',
    type: 'dot',
    map: {
      No: 'success',
      Suspended: 'danger',
    },
  },
  {
    label: 'Donation',
    visibility: false,
    filter: {
      key: 'isDonationEnabled',
      type: 'select',
      options: [
        { value: true, text: 'Enabled' },
      ],
    },
  },
  {
    label: 'Membership',
    visibility: false,
    filter: {
      key: 'isMembershipEnabled',
      type: 'select',
      options: [
        { value: true, text: 'Enabled' },
      ],
    },
  },
  {
    label: 'Listing',
    visibility: false,
    filter: {
      key: 'hasListing',
      type: 'select',
      options: [
        { value: true, text: 'Active' },
      ],
    },
  },
]
